import React from 'react'
import cl from './ChannelListItem.module.css' 
import Stb from "../../API/Stb";

const ChannelListItem = ({ channel, idx, channelState }) => {
  
  return (
        <tr className={channelState.activeChannelIndex === idx ? 'activeChanel' : 'noActive'}>
            <td className={cl.number}>{channel.channel_number} </td>
            <td className={cl .logoChanel}>
                <div className={cl.wb}> 
                  <img src={channel.logo} className={cl.wbimg} alt="logo" loading="lazy"/>
                </div>
            </td>
            <td className={cl.chName}>{channel.name}</td>
            <td className={cl.chEPG}>{channelState.allEpgs[idx].current}</td>
        </tr>      
  )
}

export default ChannelListItem