import { createContext, useReducer } from "react";  
import { tvReducer, initialTvState } from "../reducers/tv"; 
import { playerReducer,initialPlayerState } from "../reducers/player"; 
import { epgReducer,initialEpgState } from "../reducers/epg"; 
import { appReducer,initialAppState } from "../reducers/app"; 

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  
    const [appState, appDispatch] =  useReducer(appReducer, initialAppState)  
    const [tvState,  tvDispatch] = useReducer(tvReducer, initialTvState)
    const [playerState, playerDispatch] = useReducer(playerReducer, initialPlayerState)
    const [epgState, epgDispatch] = useReducer(epgReducer, initialEpgState)
     
    const value = {
        appState: appState,
        appDispatch: appDispatch,
        tvState: tvState,
        tvDispatch: tvDispatch,
        playerState: playerState,
        playerDispatch: playerDispatch,
        epgState: epgState,
        epgDispatch: epgDispatch
    };
  
    return (
      <AppContext.Provider value={value}>
        {children}
      </AppContext.Provider>
    );
  };
  