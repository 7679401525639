export default class Utils {
  static isInt(value) {
    return (
      !isNaN(value) &&
      parseInt(Number(value)) === value &&
      !isNaN(parseInt(value, 10))
    );
  }

  static isEven(x) {
    return x % 2 === 0;
  }

  static isOdd(x) {
    return !this.isEven(x);
  }

  static concatInt(a, b) {
    return parseInt(a.toString() + b.toString());
  }

  static zeroPad(num, places) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }

  static zeroPadWP(num, places) {
    var newNum = parseInt(num) + 1;
    var zero = places - newNum.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + newNum;
  } 
 
}
