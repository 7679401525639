import React, { useState } from "react";
 
import cl from "./NoPayment.module.css";
 
const NoPayment = () => {
   
  return (
    <div>

      <div className={cl.NoPayment}>
          <p className={cl.headNo}>გაითიშა გადაუხდელობის გამო</p>
          <p>აბონენტი: {window.firstname} {window.lastname}</p>
          <p>ID:  {window.user_id}</p>
          <p>ინტერნტი: {window.packageNET}</p>
          <p>ტელევიზია: {window.packageTV}</p>  
          <p>ბალანსი: {window.balance} ლარი</p>  
      </div> 
        {console.log("NoPayment Component")}
    </div>
  );
};

export default NoPayment;
