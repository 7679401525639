import React from "react";
import { useEffect, useState } from "react";
import useTv from "../../hooks/useTv";
import { AppContext } from "../../providers/appProvider";
import Stb from "../../API/Stb";
import cl from "./AlertMessage.module.css";
import { Component } from "../../API/Component";

const AlertMessage = () => { 
  const componentName = "AlertMessage";
  const keyListenerName = "onKeyEmu" + componentName;
  const [tvState, tv] = useTv(AppContext);

  useEffect(() => {
    const onKeyEmu = (e) => {
      const androidKey = e.detail.androidKey;
      const reactKey = e.detail.key;
      switch (reactKey) {
        case window.keys.ok:
          tv.setActiveComponent(Component.EMPTY)
          break;
        default:
          console.log(keyListenerName +" unknown keyClick: " + androidKey);
      } 
    };
    window.addEventListener(keyListenerName, onKeyEmu);

    return () => {
      window.removeEventListener(keyListenerName, onKeyEmu);
    };
  },[]);
 
  return <div> 
    <div className={cl.dvrAlert}>
         {tvState.alert}
        <div className={cl.btns}>
            <div>არა</div>
            <div>კი<span></span></div> 
        </div>
    </div>

  </div>;
};

export default AlertMessage;
