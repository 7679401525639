import React from "react";
import Head from "../Head/Head"; 
import ChannelList from "../ChannelList/ChannelList";
import Weather from "../Weather/Weather"; 
import ActiveChannel from "../ActiveChannel/ActiveChannel"; 
import ClientInfo from "../ClientInfo/ClientInfo"; 
import { useEffect } from 'react'
import Stb from "../../API/Stb";

import cl from './MenuTv.module.css'
  
const MenuTv = () => {  

    useEffect(() => {
        Stb.setPlace('menu')
        return () => {
            Stb.setPlace('full');
        }
    }, [])
    
    return ( 
        <div className={cl.mainPage}>
            <div className={cl.container}>
                <div className={cl.rowTv}>
                    <div className={cl.colTvLeft}> 
                        <Head/>
                        <div className={cl.miniTV}>
                            <div className={cl.TVscreen}></div> 
                        </div>
                        <ActiveChannel/>
                        <ClientInfo/>
                    </div>
                    <div className={cl.colTvRight}>
                        <ChannelList opacity="1"/>
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default MenuTv