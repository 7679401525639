import React, { useState, useEffect } from 'react'; 
import cl from './Head.module.css' 

function Logo() {
    const [currentTime, setCurrentTime] = useState(new Date());
  
    useEffect(() => {
      const timer = setInterval(() => {
        setCurrentTime(new Date());
      }, 1000);
  
      return () => {
        clearInterval(timer);
      };
    }, []);
  
    // Set the time zone to Tbilisi (GMT+4)
    const options = {
      timeZone: 'Asia/Tbilisi',
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    };
  
    const formattedTime = currentTime.toLocaleTimeString('en-US', options);
  
  
    return (
        <div className={cl.logoTime}>
                <div className={cl.logoTop}>
                    <img src={window.logo} className={cl.logoImg} /> 
                </div>
                <div>  
                    <div className={cl.timeTop}>{formattedTime}</div>
                </div>
        </div> 
      
    );
  }
  
  export default Logo;

 