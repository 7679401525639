  
export const initialEpgState = {
    allEpg: {},
    channelEpg: [],
    reminders: {},
    activeEpgIndex: null,
    channelEpgIsLoaded: !1,
    loadedEpgChannelNumber: null
};

const channelsActions = {
    SET_ALL_EPG: "SET_ALL_EPG",
    CHANGE_CUR_EPG_INDEX: "CHANGE_CUR_EPG_INDEX",
    CHANGE_EPG_LOADED_STATUS: "CHANGE_EPG_LOADED_STATUS",
    SET_CHANNEL_EPG: "SET_CHANNEL_EPG",
    SET_LOADED_EPG_CHANNEL_NUMBER: "SET_LOADED_EPG_CHANNEL_NUMBER",
    SET_ALL_REMINDERS: "SET_ALL_REMINDERS",
    ADD_REMINDER: "ADD_REMINDER",
    REMOVE_REMINDER: "REMOVE_REMINDER"
};


export const epgReducer = (state, action) => {
  switch (action.type) {
    case channelsActions.SET_ALL_EPG:

    var epgs = {};
    action.data.forEach((eachEpg) => {
      epgs[eachEpg.channelId] ? epgs[eachEpg.channelId].push(eachEpg) : epgs[eachEpg.channelId] = [eachEpg]
    });

    return {
        ...state,
        allEpg: epgs
     };
    case channelsActions.CHANGE_CUR_EPG_INDEX: {
      return {
        ...state,
        activeEpgIndex: action.index
      };
    }
    case channelsActions.CHANGE_EPG_LOADED_STATUS: {
      return {
        ...state, 
        channelEpgIsLoaded: action.status
      };
    }
    case channelsActions.SET_CHANNEL_EPG: {
      return {
        ...state,
        channelEpg: action.status
      };
    }
    case channelsActions.SET_LOADED_EPG_CHANNEL_NUMBER: {
      return {
        ...state,
        loadedEpgChannelNumber: action.status
      };
    }
    case channelsActions.SET_ALL_REMINDERS: {
      var reminders = {};
      action.data.forEach((eachReminder) => {
        reminders[eachReminder.programmeId] = eachReminder
      });
  
      return {
          ...state,
          reminders: reminders
       };
    }
    case channelsActions.ADD_REMINDER: {
      var reminders = {};
      var newState =  {...state};
      newState.reminders[action.programmeId] = action.remind
      return newState 
    }
    case channelsActions.REMOVE_REMINDER: {
      var reminders = state.reminders

      if (reminders[action.programmeId]) {
          delete reminders[action.programmeId]
      }
      return {
        ...state,
        reminders: reminders
      };
     
    }
    default:
      return state;
  }
};