import React from "react";
import Loader from "./Loader/Loader";
import Tv from "./Tv";
import { useEffect, useState } from "react";

import useTv from "../hooks/useTv";
import useLang from "../hooks/useLang";

import { AppContext } from "../providers/appProvider";
import Stb from "../API/Stb";
import { Screen } from "../API/Screen";
import useCmd from "../hooks/useCmd";
import NoPayment from "./NoPayment/NoPayment";
 
const Main = () => {
  
  const [tvState, tv] = useTv(AppContext);
  const [cmd] = useCmd(AppContext);

  useEffect(() => {
    console.log("render useEffect")

    const intervalId = setInterval(() => {
      checkClient();
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);


  const checkClient = async () => {  
    try { 
      var clientInfo = await Stb.clientInfo(); 
      var oldClientInfo = Stb.localClientInfo();
      Stb.setClientInfo(clientInfo);
      console.log("localInfo:" + JSON.stringify(clientInfo)); 
     
      if (clientInfo.disable) {
        console.log("clientInfo.disable === true");  
        tv.setScreen(Screen.NOPAYMENT); 
        Stb.stopPlayer();
        tv.setActiveComponent("")
      } else if (oldClientInfo !== null && oldClientInfo.disable === true) {
        tv.setScreen(Screen.LOADING);
      } 
 
      if (clientInfo.cmd.lenth > 0) {
        cmd.execute(clientInfo.cmd, clientInfo.param);
      }
 
    } catch (e) {
      return;
    }
  }

  return (
    <div>
      {(() => {
        switch (tvState.screen) {
          case Screen.TV:
            return <Tv />;
           case Screen.LOADING:
            return <Loader />;
          case Screen.NOPAYMENT:
            return <NoPayment />;
          default:
            return <div>{console.log("default Log")}</div>
        }
      })()}
    </div>
  );
};

export default Main;
