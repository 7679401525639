import axios from "axios";

export default class Stb {
  static async getAllChannel() {
    console.log("mac:"+ window.mac)
    const response = await axios.get(
      "https://isp.netpark.ge/get-channels",
      {
        params: {
          mac: window.mac,
          token: window.token,
          language: window.language,
          version: window.version,
        },
      }
    );
    return response;
  } 

  static async register() {
    console.log("mac:"+ window.mac)

    const response = await axios.get(
      "https://isp.netpark.ge/device-tv/register",
      {
        params: {
          mac: window.mac, 
          language: window.language,
          version: window.version,
        },
      }
    );
    return response.data;
  }
  

  static async clientInfo() {
    console.log("clientInfo mac:"+ window.mac)

    const response = await axios.get(
      "https://isp.netpark.ge/get-client",
      {
        params: {
          mac: window.mac 
        },
      }
    );
    return response.data;
  }
 
  static async fetchDvrTimes(url) {
    var response;
    
    try {
      response = await axios.get(url);
    } catch (e) {
      console.log("error fetchDvrTimes: " + e.message);
      return null;
    }

    let dvrTimes = Object.values(response.data)[0];
    return dvrTimes;
  }
 

  static async initStb() {
    window.mac = window.MyJSInterface.getMac().toLowerCase();
    window.token =  window.MyJSInterface.getToken().toLowerCase();
    window.language = "language"; //window.MyJSInterface.getLanguage();
    window.version = "version"; //window.MyJSInterface.getVersion();
    window.streamIndex = "/index.mpd"
    window.rewindUrl = "/index-%t-%p.mpd"
    window.previewUrl = "/%y/%mo/%d/%h/%m/%s-preview.mp4"
    window.statisticsUrl = "/recording_status.json"

    window.keys = {
      num0: 0,
      num1: 1,
      num2: 2,
      num3: 3,
      num4: 4,
      num5: 5,
      num6: 6,
      num7: 7,
      num8: 8,
      num9: 9,
      down: 10,
      up: 11,
      left: 12,
      right: 13,
      ok: 14,
      ret: 15,
      pausePlay: 16,
      volumeUp: 17,
      volumeDown: 18,
      volumeMute: 19,
      rewind: 20,
      audioLang: 21,
      unknown: 200,
    };
  }

  static async getStbMac(id) {
    return window.mac;
  }

  static async getToken(id) {
    return window.token;
  }

  static async tokenizerStream(url) {
    var tokenParam = "&token=" + window.mac + "-" + window.token;
    var tokenizerUrl = url + tokenParam;
    return tokenizerUrl;
  }

  static async changeActiveComponent(name) {
    window.MyJSInterface.changeActiveComponent(name);
  }

  static getPlayerInfo() {
    var playerInfoJson = window.MyJSInterface.getPlayerInfo();
    var playerInfoStb = JSON.parse(playerInfoJson);
    return playerInfoStb;
  }

  static setVolume(volume) {
    window.MyJSInterface.setVolume(volume);
  }

  static goToLive() {
    window.MyJSInterface.goToLive();
  }

  static stopPlayer() {
    window.MyJSInterface.stopPlayer();
  }

  static startRewind(direction, rewindSpeed) { 
    window.MyJSInterface.startRewind(direction, rewindSpeed, 0)   
  }

  static setPlace(place) {
    window.MyJSInterface.setPlace(place);
  }

  static setDvrTimes(from, to) {
    window.MyJSInterface.setDvrTimes(from,to);
  }

  static setClientInfo(info) {
    console.log("setClientInfo:" + JSON.stringify(info));

    window.user_id = info.user_id
    window.company = info.company 
    window.firstname = info.firstname 
    window.lastname = info.lastname 
    window.logo = info.logo 
    window.packageTV = info.packageTV
    window.packageNET = info.packageNET
    window.balance = info.balance
    window.disable = info.disable
  }

  static localClientInfo() { 
      var clientInfo =  
      {
        user_id : window.user_id,
        company : window.company, 
        firstname : window.firstname, 
        lastname : window.lastname , 
        logo : window.logo , 
        packageTV  : window.packageTV,
        packageNET  : window.packageNET,
        balance  : window.balance,
        disable : window.disable
      };
      console.log("localClientInfo:" + JSON.stringify(clientInfo));
 
      return clientInfo 
  }

  static saveToken(token) {
    window.token = token
    window.MyJSInterface.saveToken(token);

  }  
  
  static getAudioList() {
    var audioList = window.MyJSInterface.getAudioList();
     var realList = JSON.parse(audioList);
    return realList
  } 

  static playLiveFromjs(stream_live, preview_url, stream_rewind, timestamp) {
    var oldChannelInfoString = window.MyJSInterface.playFromJS(
      stream_live,
      preview_url,
      stream_rewind,
      timestamp,
    );  

    var oldChannelInfo = JSON.parse(oldChannelInfoString);
    return oldChannelInfo
  }

  static setAudioLang(lang) {
    window.MyJSInterface.setAudioLang(lang);
  }


  static saveLanguage(lang) {
    window.MyJSInterface.saveLanguage(lang);
  }

  static saveLanguage(lang) {
    window.MyJSInterface.saveLanguage(lang);
  }

  static getLanguage() {
    window.MyJSInterface.getLang();
  } 

  static restartApp() {
    window.MyJSInterface.restartApp();
  }

  static restartDevice() {
    window.MyJSInterface.restartDevice();
  } 

  static cmdAndroid(command) {
    window.MyJSInterface.cmdAndroid(command);
  } 

  static getCurrentEpg(epgs) {
    var foundEpg = null

    if (epgs === null) {
      return foundEpg
    }

    const currentSec = Math.floor(new Date().getTime() / 1000);

    epgs.forEach((item) => {
      var start = item.start
      var end = start + item.duration  

      if ( currentSec >= start && currentSec <= end ) {
        console.log("found :" + item.name) 
        foundEpg = item;
      } 
    }); 

    return foundEpg
  }
}
