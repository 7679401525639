import React from "react";
import MenuTv from "./MenuTv/MenuTv";
import FooterTv from "./FooterTv/FooterTv";

import { useEffect, useState } from "react";
import { AppContext } from "../providers/appProvider";
import usePlayer from "../hooks/usePlayer";
import useTv from "../hooks/useTv";

import ChannelChanger from "./ChannelChanger/ChannelChanger";
import RewindTime from "./RewindTime/RewindTime";
import VolumeBar from "./VolumeBar/VolumeBar";
import TimeShifter from "./TimeShifter/TimeShifter";
import DvrAlert from "./DvrAlert/DvrAlert";
import Stb from "../API/Stb";
import {Component}  from "../API/Component";
import AlertMessage from "./AlertMessage/AlertMessage";
import ArchiveAlert from "./ArchiveAlert/ArchiveAlert"; 
import LanguageSetting from "./LanguageSetting/LanguageSetting";


const Tv = () => {
  const keyListenerName = "onKeyEmu";
  const [direction, setDirection] = useState({ status: 2 });

  const [tvState, tv] = useTv(AppContext);
  const [playerState, player] = usePlayer(AppContext);
  const [keyDownNum, setkeyDownNum] = useState({ key: "" });
  
  const pausePlay = () => {
    var playerInfo = Stb.getPlayerInfo();

    console.log(JSON.stringify(playerInfo));

    if ((playerInfo.state === "onRewind")) {
      tv.setArchiveUrl(playerInfo.timestamp);
      return
    }

    if (playerInfo.state === "onPause") {
      tv.setArchiveUrl(playerInfo.timestamp);
      tv.showTvMenu(false, false);
    } else { 
      window.MyJSInterface.pauseTvPlayer();
      tv.showFooterTv(true);
      tv.showTvMenu(false, false);
    }
  };

  useEffect(() => {
    //tvState.allChannels.forEach((channel) => {
    //  const img = new Image();
    //  img.src = channel.logo;
    //});
    const currentFolder = window.location.origin + process.env.PUBLIC_URL;
    const imageUrl = currentFolder + '/rustavi2.png';
    new Image().src = imageUrl
  }, []);

  useEffect(() => {
     console.log('useEffect: tvState.archiveCurrent:' + tvState.archiveCurrent)
     if (tvState.archiveCurrent !== null) {
            tv.setActiveComponent(Component.ARCHIVE_ALERT)
     }
  }, [tvState.archiveCurrent]);

  useEffect(() => {
    console.log('[tvState.activeChannel: ' + JSON.stringify(tvState.activeChannel))

    tv.showFooterTv(true); 
    tv.setDvrTimes(tvState.activeChannel.statistics_url);
    
    const intervalId = setInterval(() => {
      tv.setDvrTimes(tvState.activeChannel.statistics_url);

      var playerInfo = Stb.getPlayerInfo();

      if (playerInfo.archiveNotiferEnable) {
        tv.setActiveComponent(Component.DVR_ALERT);
      }
    }, 15000);

    var oldChannelInfo = Stb.playLiveFromjs(
      tvState.activeChannel.stream_live,
      tvState.activeChannel.preview_url,
      tvState.activeChannel.stream_rewind,
      tvState.activeChannel.timestamp,
    );  

    console.log('oldChannelInfo: ' + JSON.stringify(oldChannelInfo))

    if (oldChannelInfo.state !== "onLive" ) {
        tv.addArchiveChannel(oldChannelInfo.streamUrl, oldChannelInfo.timestamp)
    }
 
    return () => { 
      clearInterval(intervalId);
    };
  }, [tvState.activeChannel] );
 
  useEffect(() => {
    const onKeyEmu = (e) => {
      const reactKey = e.detail.key;
      const androidKey = e.detail.androidKey;
      console.log(
        "onKeyEmu reactKey: " + reactKey + " androidKey: " + androidKey
      );

      if (reactKey >= window.keys.num0 && reactKey <= window.keys.num9) {
        setkeyDownNum({ ...keyDownNum, key: reactKey });
        return;
      }

      switch (reactKey) {
        case window.keys.down:
          tv.upActiveChannel();
          break;
        case window.keys.up:
          tv.downActiveChannel();
          break;
        case window.keys.right:
          setDirection({ ...direction, status: 0 });
          break;
        case window.keys.ok:
          setDirection({ ...direction, status: 2 });
          break;
        case window.keys.rewind:
          tv.setActiveComponent(Component.REWIND_TIME);
          break;
        case window.keys.left:
          setDirection({ ...direction, status: 1 });
          //window.MyJSInterface.pauseAndPlayTvPlayer()
          break;
        case window.keys.volumeUp:
          player.setVolume(5);
          break;
        case window.keys.volumeDown:
          player.setVolume(-5);
          break;
        case window.keys.volumeMute:
          player.muteVolume();
          break;
        case window.keys.unknown:
          console.log("onKeyEmu unknown keyClick: " + androidKey);
          break;
        case window.keys.pausePlay:
          pausePlay();
          break;
        case window.keys.audioLang:
            tv.setActiveComponent(Component.AUDIO_LANG);
            break;
        default:
          console.log("onKeyEmu unknown keyClick: " + androidKey);
      }
    };

    tv.showFooterTv(true); 
    window.addEventListener(keyListenerName, onKeyEmu);

    return () => {
      window.removeEventListener(keyListenerName, onKeyEmu);
    };
  }, []);

  return (
    <div>
      {tvState.showTvMenu && <MenuTv />}
      {tvState.showFooterTv && <FooterTv />}

      <ChannelChanger keyDownNum={keyDownNum} />
      <VolumeBar />
      <TimeShifter direction={direction} />

      {(() => {
        switch (tvState.activeComponent) {
          case Component.REWIND_TIME:
            return <RewindTime />;
          case Component.ALERT_MESSAGE:
            return <AlertMessage/>;
          case Component.DVR_ALERT:
            return <DvrAlert />;
          case Component.AUDIO_LANG:
             return <LanguageSetting />;
          case Component.ARCHIVE_ALERT:
             return <ArchiveAlert />;
          default:
            return;
        }
      })()}
    </div>
  );
};

export default Tv;

/*  function simulationKey(e) { 
    switch (e.key) {
      case "ArrowLeft":
        tvDispatch({ type: 'CHANGE_SHOW_FOOTER_TV', status: false })
        tvDispatch({ type: 'CHANGE_SHOW_TV_MENU' })
          break;
      case "ArrowRight":
        tvDispatch({ type: 'CHANGE_ACTIVE_CHANNEL', index: -1 })
        break;
      case "ArrowUp":
        tvDispatch({ type: 'UP_ACTIVE_CHANNEL' })
          break;
      case "ArrowDown":
        tvDispatch({ type: 'DOWN_ACTIVE_CHANNEL' })
          break;
  }
  }


  useEffect(() => {
     window.addEventListener("keydown", simulationKey)
  
     return () => {
      window.removeEventListener("keydown", simulationKey)
     }
  }, []) */
