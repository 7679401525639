import { useContext } from 'react';

const usePlayer = (context) => {
    const  {playerState, playerDispatch} = useContext(context);

    function setDirection(direction) {
        playerDispatch({ type: "SET_PLAYER_DIRECTION", direction: direction });
    }

    function setVolume(volume) {
        playerDispatch({ type: "SET_PLAYER_VOLUME", volume: volume });
    }

    function muteVolume() {
        playerDispatch({ type: "SET_PLAYER_VOLUME_MUTE" });
    }

    function isRewinding(status) {
        playerDispatch({ type: "SET_PLAYER_IS_REWINDING", status: status });
    }

    function setRewindSpeed(speed) {
        playerDispatch({ type: "SET_PLAYER_REWIND_SPEED", speed: speed });
    }

    function setTimestamp(ts) {
        playerDispatch({ type: "SET_PLAYER_TIMESTAMP", timestamp: ts });
    }

    function setPlaying(status) {
        playerDispatch({ type: "SET_PLAYER_IS_PLAYING", status: status });
    }
    
    function setIsLive(status) {
        playerDispatch({ type: "SET_PLAYER_IS_LIVE", status: status });
    }
 
 
    const player = { 
        setDirection,
        setVolume,
        muteVolume,
        isRewinding,
        setRewindSpeed,
        setTimestamp,
        setPlaying,
        setIsLive
      };

    return [
        playerState,
        player
    ];
}

export default usePlayer;
