import React from "react";
import { useEffect, useState } from "react";
import { AppContext } from "../../providers/appProvider";
import Utils from "../../API/Utils";
import useTv from "../../hooks/useTv";

import cl from "./RewindTime.module.css";

const RewindTime = ({ keyDownNum }) => {
  const componentName = "RewindTime";
  const keyListenerName = "onKeyEmu" + componentName;
  const [message, setMessage] = useState({ message: "", show: false });
  var nowArr = dateToArr();
  var timeoutId;

  const showMessage = (msg) => {
    setMessage({ ...message, message: msg, show: true });
    timeoutId = setTimeout(() => {
      setMessage({ ...message, message: "", show: false });
    }, 2000);
  };

  const [dateArray, setDateArray] = useState({
    idx: 0,
    isFirst: true,
    data: nowArr,
  });

  const [tvState, tv] = useTv(AppContext);

  const arrToDate = (arr) => {
    console.log(JSON.stringify(arr));

    const monthDay = arr[0];
    const month = arr[1];
    const hour = arr[2];
    const minute = arr[3];
    const date = new Date();

    if (monthDay > 31 || minute > 59 || hour > 23 || month > 12) {
      return false;
    }

    if (monthDay < 1 || minute < 0 || hour < 0 || month < 1) {
      return false;
    }

    date.setDate(monthDay);
    date.setMonth(month);
    date.setHours(hour);
    date.setMinutes(minute);
    date.setSeconds(0);

    return date;
  };

  function dateToArr() {  
    console.log("Rewind getTimezoneOffset:" + offsetInMinutes);
    const georgianDate = new Date(); // April 23, 2023 17:30:00 UTC

    const offsetInMinutes = georgianDate.getTimezoneOffset();
    const georgianTimezoneOffset = 240 + offsetInMinutes; 

    georgianDate.setUTCMinutes(
      georgianDate.getUTCMinutes() + georgianTimezoneOffset
    )

    var arrDate = [0, 0, 0, 0];

    arrDate[0] = georgianDate.getDate();
    arrDate[1] = georgianDate.getMonth();
    arrDate[2] = georgianDate.getHours();
    arrDate[3] = georgianDate.getMinutes();

    return arrDate;
  }

  useEffect(() => {
    const onKeyEmu = (e) => {
      const reactKey = e.detail.key;
      //const androidKey = e.detail.androidKey;

      if (reactKey >= window.keys.num0 && reactKey <= window.keys.num9) {
        setDateArray((prev) => {
          console.log(JSON.stringify(prev));
          var newArr = [...prev.data];
          var activeNum = prev.data[prev.idx];
          var isFirst = prev.isFirst;
          var isPassed = true;

          if (isFirst) {
            activeNum = reactKey;
          } else {
            activeNum = activeNum * 10 + reactKey;
          }

          newArr[prev.idx] = activeNum;

          if (arrToDate(newArr)) {
            isPassed = false;
          }

          var idx = isPassed & !isFirst ? ++prev.idx : prev.idx;
          var newIndex = idx > 3 ? 3 : idx;

          return { ...prev, data: newArr, idx: newIndex, isFirst: !isFirst };
        });
        return;
      }

      switch (reactKey) {
        case window.keys.down:
          setDateArray((prev) => {
            var num = prev.data[prev.idx];
            var newArr = [...prev.data];
            newArr[prev.idx] = num - 1;

            if (!arrToDate(newArr)) {
              return prev;
            }

            return { ...prev, data: newArr, isFirst: true };
          });
          break;
        case window.keys.up:
          setDateArray((prev) => {
            var num = prev.data[prev.idx];
            var newArr = [...prev.data];
            newArr[prev.idx] = num + 1;

            if (!arrToDate(newArr)) {
              return prev;
            }

            return { ...prev, data: newArr, isFirst: true };
          });
          break;
        case window.keys.right:
          setDateArray((prev) => {
            var idx = ++prev.idx;
            var newIndex = idx > 3 ? 3 : idx;

            return { ...prev, idx: newIndex, isStart: true };
          });
          break;
        case window.keys.left:
          setDateArray((prev) => {
            var idx = --prev.idx;
            var newIndex = idx < 0 ? 0 : idx;

            return { ...prev, idx: newIndex, isStart: true };
          });
          break;
        case window.keys.ok:
          setDateArray((prev) => {
            var newArr = [...prev.data];
            var archiveDate = arrToDate(newArr);

            if (!archiveDate) {
              return prev;
            }

            const offsetInMinutes = archiveDate.getTimezoneOffset();

            console.log("Rewind getTimezoneOffset:" + offsetInMinutes);
 
            const georgianTimezoneOffset = 240 + offsetInMinutes;
            archiveDate.setUTCMinutes(
              archiveDate.getUTCMinutes() - georgianTimezoneOffset
            );
            console.log("Rewind timestamp:" + archiveDate.toTimeString());
            const timestamp = archiveDate.getTime();
            console.log("Rewind toUTCString:" + archiveDate.toUTCString());
            const unixTimestamp = Math.floor(timestamp / 1000);

            if (
              unixTimestamp > tvState.dvrEnd ||
              unixTimestamp < tvState.dvrStart
            ) {
              console.log(
                "dvr not found: " +
                  unixTimestamp +
                  " dvrStart:" +
                  tvState.dvrStart +
                  " dvrEnd:" +
                  tvState.dvrEnd
              );
              showMessage("მითითებულ დროში არ არსებობს ჩანაწერი");
              return prev;
            }

            tv.setArchiveUrl(unixTimestamp);
            tv.setActiveComponent("");
            return prev;
          });
           break;
        case window.keys.ret:
          tv.setActiveComponent("");
          break;
      }
    };

    window.addEventListener(keyListenerName, onKeyEmu);

    return () => {
      window.removeEventListener(keyListenerName, onKeyEmu);
    };
  }, [tvState.dvrStart, tvState.dvrEnd]);

  return (
    <div>
      <div className={cl.rewindBox}>
        <div className={cl.dayMonth}>
          <div className={dateArray.idx === 0 || dateArray.idx === 1 ? cl.valueBig  + ' ' + cl.active : cl.valueBig}>
            {Utils.zeroPad(dateArray.data[0], 2)}/{Utils.zeroPadWP(dateArray.data[1], 2)}
          </div>
          <div className={cl.text}>თარიღი</div>
        </div>
        <div className={cl.hour}>
          <div className={dateArray.idx === 2 ? cl.value  + ' ' + cl.active : cl.value}>{Utils.zeroPad(dateArray.data[2], 2)}</div>
          <div className={cl.text}>საათი</div>
        </div>
        <div className={cl.minute}>
          <div className={dateArray.idx === 3 ? cl.value  + ' ' + cl.active : cl.value}>{Utils.zeroPad(dateArray.data[3], 2)}</div>
          <div className={cl.text}>წუთი</div>
        </div>
      </div>

     
        {message.show && (
          <h1 className={cl.errorTime}>
             {message.message} 
          </h1>
        )}
      
    </div>
  );
};

export default RewindTime;
