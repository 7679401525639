  
 export const initialAppState = {
  loaded: true,
  activeLayer: "",
  mainLayer: {
      rightContent: "channels",
      activeComponent: "channels"
  },
  screen: "loader",
  channelQuickSwitch: "",
  showInfoBar: !0,
  showNoRewindIcon: !1,
  showVolumeBar: !1,
  showQuickRewind: !1,
  showAndroidMenu: !0,
  showRewindPannel: !1,
  errorMessage: null,
  alertMessage: {
      message: null,
      restart: !1,
      confirm: !1,
      id: null
  }
};

const appActions = {
    APP_LOADED: "APP_LOADED",
    CHANGE_RIGHT_CONTENT: "CHANGE_RIGHT_CONTENT",
    CHANGE_ACTIVE_COMPONENT: "CHANGE_ACTIVE_COMPONENT",
    CHANGE_ACTIVE_LAYER: "CHANGE_ACTIVE_LAYER",
    CHANGE_QUICK_CHANNEL_SWITCH: "CHANGE_QUICK_CHANNEL_SWITCH",
    TOGGLE_INFO_BAR: "TOGGLE_INFO_BAR",
    TOGGLE_REWIND_PANNEL: "TOGGLE_REWIND_PANNEL",
    TOGGLE_NO_REWIND_ICON: "TOGGLE_NO_REWIND_ICON",
    TOGGLE_VOLUME_BAR: "TOGGLE_VOLUME_BAR",
    TOGGLE_QUICK_REWIND: "TOGGLE_QUICK_REWIND",
    SHOW_ANDROID_MENU: "SHOW_ANDROID_MENU",
    SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
    SET_ALERT_MESSAGE: "SET_ALERT_MESSAGE",
    SHOW_FULLTV: "SHOW_FULLTV",
    SHOW_MINITV: "SHOW_MINITV",
    CHANGE_SCREEN: "CHANGE_SCREEN" 
};

export const appReducer = (state, action) => {
  switch (action.type) {
    case appActions.SET_ALL_CHANNELS:
      return {
        ...state,
        loaded: action.loaded
      };
    case appActions.CHANGE_RIGHT_CONTENT: {
      return {
        ...state,
        mainLayer: {...state.mainLayer, rightContent: action.content}
      };
    }
    case appActions.CHANGE_ACTIVE_COMPONENT: {
      return {
        ...state,
        mainLayer: {...state.mainLayer, activeComponent: action.content}
      };
    }
    case appActions.CHANGE_ACTIVE_LAYER: {
      return {
        ...state,
        activeLayer: action.activeLayer
      };
    }
    case appActions.CHANGE_SCREEN: {
      return {
        ...state,
        screen: action.screen
      };
    }
    case appActions.CHANGE_QUICK_CHANNEL_SWITCH: {
      return {
        ...state,
        channelQuickSwitch: action.number
       };
    }
    case appActions.TOGGLE_INFO_BAR: {
      return {
        ...state,
        showInfoBar: action.showInfoBar
      };
    }
    case appActions.TOGGLE_REWIND_PANNEL: {
      return {
        ...state,
        showRewindPannel: action.showRewindPannel
      };
    }
    case appActions.TOGGLE_NO_REWIND_ICON: {
      return {
        ...state,
        showNoRewindIcon: action.showNoRewindIcon
      };
    }
    case appActions.TOGGLE_VOLUME_BAR: {
      return {
        ...state,
        showVolumeBar: action.showVolumeBar
      };
    }
    case appActions.TOGGLE_QUICK_REWIND: {
      return {
        ...state,
        showQuickRewind: action.showQuickRewind
      };
    }
    case appActions.SHOW_ANDROID_MENU: {
      return {
        ...state,
        showAndroidMenu: action.showAndroidMenu
      };
    }
    case appActions.SET_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.errorMessage
      };
    }
    case appActions.SET_ALERT_MESSAGE: {
      return {
        ...state,
        alertMessage: action.alertMessage
      };
    }
    case appActions.SHOW_FULLTV: { 
        return {
          ...state,
          activeLayer: "fulltv"
        };
      } 
      case appActions.SHOW_MINITV: { 
        return {
          ...state,
          activeLayer: "minitv"
        };
      }  
    default:
      return state;
  }
};
 