import React from "react";
import cl from './ActiveChannel.module.css'
import { useContext } from 'react'
import { AppContext } from '../../providers/appProvider'

const ActiveChannel = () => {

    const { tvState } = useContext(AppContext)

    return ( 
        <div className={cl.activeChanelNow}>
            <div className={cl.activeChanelBox}>
                <img className={cl.activeChanelImg} src={tvState.activeChannel.logo} />
                <div className={cl.activeChanelName}>{tvState.activeChannel.name}</div>
            </div>
        </div> 
    )
}

export default ActiveChannel

 