import { cloneElement } from "react";
import Stb from "../API/Stb";
import { act } from "react-dom/test-utils";

export const initialTvState = {
  allChannels: [{name: "imedi"},{name: "rustavi2"},{name: "mtavari"},{name: "tvpirveli"},{name: "bbc"},{name: "cnn"}, {name: "maestro"}, {name: "gds"}],
  genres: [],
  allEpgs:[],
  renderedChannels: [],
  activeChannelIndex: 0,
  showTvMenu: false,
  showFooterTv: false,
  footerStartTs: 0,
  activeUserNumber: null,
  activeChannel: {},
  previusChannelNumber: null,
  epgChannel: {},
  channelToChange: null,
  showChannelNumberChange: !1,
  activeGenreIndex: null,
  activeGenre: {},
  sort: "number",
  filter: "all",
  showSort: false,
  showFilter: false,
  archivedTimes: {},
  archiveUrl: "",
  liveUrl: "",
  dvrStart: 0,
  dvrEnd: 0,
  screen: "loader",
  alert: "", 
  lang: "",
  archiveMap: new Map(), 
  archiveCurrent: null, 
};

const tvActions = {
  SET_ALL_CHANNELS: "SET_ALL_CHANNELS",
  SET_ALL_EPGS: "SET_ALL_EPGS",
  SET_ALL_RENDERED: "SET_ALL_RENDERED",
  SET_ALL_GENRES: "SET_ALL_GENRES",
  CHANGE_ACTIVE_CHANNEL_INDEX: "CHANGE_ACTIVE_CHANNEL_INDEX",
  UP_ACTIVE_CHANNEL_INDEX: "UP_ACTIVE_CHANNEL_INDEX",
  DOWN_ACTIVE_CHANNEL_INDEX: "DOWN_ACTIVE_CHANNEL_INDEX",
  UP_ACTIVE_CHANNEL: "UP_ACTIVE_CHANNEL",
  DOWN_ACTIVE_CHANNEL: "DOWN_ACTIVE_CHANNEL",
  CHANGE_ACTIVE_GENRE_INDEX: "CHANGE_ACTIVE_GENRE_INDEX",
  CHANGE_ACTIVE_CHANNEL: "CHANGE_ACTIVE_CHANNEL",
  CHANGE_ACTIVE_CHANNEL_MENU: "CHANGE_ACTIVE_CHANNEL_MENU",  
  CHANGE_EPG_CHANNEL: "CHANGE_EPG_CHANNEL",
  CHANGE_ACTIVE_GENRE: "CHANGE_ACTIVE_GENRE",
  CHANGE_SORT_VALUE: "CHANGE_SORT_VALUE",
  CHANGE_FILTER_VALUE: "CHANGE_FILTER_VALUE",
  SET_CHANNEL_TO_CHANGE: "SET_CHANNEL_TO_CHANGE",
  SET_ACTIVE_USER_NUMBER: "SET_ACTIVE_USER_NUMBER",
  TOGGLE_SHOW_CHANNEL_NUMBER_CHANGE: "TOGGLE_SHOW_CHANNEL_NUMBER_CHANGE",
  TOGGLE_SHOW_FILTER: "TOGGLE_SHOW_FILTER",
  TOGGLE_SHOW_SORT: "TOGGLE_SHOW_SORT",
  RESET_CHANNEL_NUMBERS: "RESET_CHANNEL_NUMBERS",
  SET_CHANNEL_ARCHIVED_TIME: "SET_CHANNEL_ARCHIVED_TIME",
  CHANGE_SHOW_TV_MENU: "CHANGE_SHOW_TV_MENU", 
  CHANGE_SHOW_FOOTER_TV: "CHANGE_SHOW_FOOTER_TV",   
  CHANGE_ACTIVE_COMPONENT: "CHANGE_ACTIVE_COMPONENT",
  CHANGE_ARCHIVE_URL: "CHANGE_ARCHIVE_URL",
  SET_DVR_TIMES: "SET_DVR_TIMES", 
  CHANGE_SCREEN: "CHANGE_SCREEN", 
  CHANGE_ALERT: "CHANGE_ALERT",
  CHANGE_LANG: "CHANGE_LANG",
  ADD_TO_ARCHIVE_MAP: "ADD_TO_ARCHIVE_MAP",
  DELETE_FROM_ARCHIVE_MAP: "DELETE_FROM_ARCHIVE_MAP"
}; 

export const tvReducer = (state, action) => {
  switch (action.type) {
    case tvActions.SET_ALL_CHANNELS:
      return {
        ...state,
        allChannels: action.payload
      };
      case tvActions.SET_ALL_EPGS:
        return {
          ...state,
          allEpgs: action.payload
        };
      case tvActions.SET_ALL_RENDERED:
        return {
          ...state,
          renderedChannels:  [...state.renderedChannels, action.payload]
        };
    case tvActions.SET_ALL_GENRES: {
      return {
        ...state,
        genres: action.payload
      };  
    }
    case tvActions.CHANGE_ACTIVE_CHANNEL_INDEX: {
      return {
        ...state,
        activeChannelIndex: action.index
      };
    }
    case tvActions.UP_ACTIVE_CHANNEL_INDEX: { 
      const newIndex = state.activeChannelIndex + 1

      if ((newIndex < 0)  || ((state.allChannels.length-1) < newIndex)) {
           return state
      }

      return {
        ...state,
        activeChannelIndex: newIndex 
      };
    }
    case tvActions.DOWN_ACTIVE_CHANNEL_INDEX: { 
      const newIndex = state.activeChannelIndex - 1
 
      if ((newIndex < 0)  || ((state.allChannels.length-1) < newIndex)) {
           return state
      }
      
      return {
        ...state,
        activeChannelIndex: newIndex
      };
    }
    case tvActions.UP_ACTIVE_CHANNEL: { 
      const newIndex = state.activeChannelIndex + 1
      const archiveMap = new Map(state.archiveMap);
      console.log("UP_ACTIVE_CHANNE newIndex:"+ newIndex)

      if ((newIndex < 0)  || ((state.allChannels.length-1) < newIndex)) {
           return state
      }

      if (state.showTvMenu === false) { 
        const newChannel = state.allChannels[newIndex]
        const newStreamUrl = newChannel.stream_live

        if (archiveMap.has(newStreamUrl)) {
          console.log("UP_ACTIVE_CHANNEL vola archive:"+ newIndex)

          const val = archiveMap.get(newStreamUrl);
          const archive = {streamUrl: newStreamUrl, timestamp: val, idx: newIndex}
          return {
            ...state,
            archiveCurrent: archive  
          }; 
        } 

        return {
          ...state,
          activeChannelIndex: newIndex,
          activeChannel: newChannel 
        };
      } else {
        return {
          ...state,
          activeChannelIndex: newIndex 
        };
      }
    }
    case tvActions.DOWN_ACTIVE_CHANNEL: { 
      const newIndex = state.activeChannelIndex - 1
      const archiveMap = new Map(state.archiveMap);

      console.log("DOWN_ACTIVE_CHANNEL newIndex:"+ newIndex)

      if ((newIndex < 0)  || ((state.allChannels.length-1) < newIndex)) {
           return state
      }

      if (state.showTvMenu === false) {
        const newChannel = state.allChannels[newIndex]
        const newStreamUrl = newChannel.stream_live

        if (archiveMap.has(newStreamUrl)) {
          console.log("UP_ACTIVE_CHANNEL vola archive:"+ newIndex)

          const val = archiveMap.get(newStreamUrl);
          const archive = {streamUrl: newStreamUrl, timestamp: val, idx: newIndex}
          return {
            ...state,
            archiveCurrent: archive  
          }; 
        } 

        return {
          ...state,
          activeChannelIndex: newIndex,
          activeChannel: newChannel 
        };
      } else  {
        return {
          ...state,
          activeChannelIndex: newIndex 
        };
      }
    } 
    case tvActions.CHANGE_ACTIVE_CHANNEL_MENU: {
      var newState = {
       ...state
     } 

     var newChannel = state.allChannels[state.activeChannelIndex]
     const newStreamUrl = newChannel.stream_live
     const archiveMap = new Map(state.archiveMap)

     if (archiveMap.has(newStreamUrl)) {
      console.log("UP_ACTIVE_CHANNEL vola archive:"+ state.activeChannelIndex)

      const val = archiveMap.get(newStreamUrl);
      const archive = {streamUrl: newStreamUrl, timestamp: val, idx: state.activeChannelIndex}
      return {
        ...state,
        archiveCurrent: archive  
      }; 
    } 
     const modifiedChannel = {
       ...newChannel,  
       timestamp:  action.timestamp
     };

     newState = {
       ...state,
       activeChannel: modifiedChannel
     };
     
     return newState;

     /*return newChanel.genreId === state.activeGenre.id || newChanel.isFavorite && newState.activeGenre.favourites || (newState.activeGenre = newState.genres[0]) && (newState.activeGenreIndex = 0), 
            state.activeChannel.number && newChanel.number !== state.activeChannel.number && (newState.previusChannelNumber = state.activeChannel.number), 
            newState; */
   }
    case tvActions.CHANGE_ACTIVE_CHANNEL: {
       var newState = {
        ...state
      }

      if ((action.index < 0)  || ((state.allChannels.length-1) < action.index )) {
        return state
      }

      var newChannel = state.allChannels[action.index]
      const newStreamUrl = newChannel.stream_live
      const archiveMap = new Map(state.archiveMap)

      if (archiveMap.has(newStreamUrl)) {
          console.log("CHANGE_ACTIVE_CHANNEL archiveMap exist :" + newStreamUrl)

          const val = archiveMap.get(newStreamUrl);
          const archive = {streamUrl: newStreamUrl, timestamp: val, idx: action.index}
          return {
            ...state,
            archiveCurrent: archive  
          }; 
      }

      const modifiedChannel = {
        ...newChannel,  
        timestamp:  action.timestamp
      };

      console.log("CHANGE_ACTIVE_CHANNEL:" + action.index)

      newState = {
        ...state,
        activeChannel: modifiedChannel,
        activeChannelIndex:action.index
      };
      
      return newState;

      /*return newChanel.genreId === state.activeGenre.id || newChanel.isFavorite && newState.activeGenre.favourites || (newState.activeGenre = newState.genres[0]) && (newState.activeGenreIndex = 0), 
             state.activeChannel.number && newChanel.number !== state.activeChannel.number && (newState.previusChannelNumber = state.activeChannel.number), 
             newState; */
    }
    case tvActions.CHANGE_SHOW_TV_MENU: { 
      var showMenuState = action.status
      
      if (action.reverse == true) {
        showMenuState = !state.showTvMenu
      }

      return {
        ...state,
        showTvMenu: showMenuState
      }; 
    }
    case tvActions.CHANGE_SHOW_FOOTER_TV: {
      var footerStartTs = 0

      if (action.status == true) {
        footerStartTs = Math.floor(Date.now() / 1000)
      }

      return {
       ...state,
       showFooterTv: action.status,
       footerStartTs: footerStartTs,
     }; 
   }
    case tvActions.CHANGE_ACTIVE_GENRE_INDEX: {
      return {
        ...state,
        activeGenreIndex: action.index
      };
    } 
    case tvActions.CHANGE_ACTIVE_GENRE: {
      return {
        ...state,
        activeGenre: action.genre
      };
    }  
    case tvActions.CHANGE_EPG_CHANNEL: {
      return {
        ...state,
        activeGenre: action.epgChannel
      };
    }
    case tvActions.CHANGE_SORT_VALUE: {
      return {
        ...state,
        sort: action.sort
      };
    }
    case tvActions.CHANGE_FILTER_VALUE: {
      return {
        ...state,
        filter: action.filter, 
        activeChannelIndex: null
      };
    }
    case tvActions.SET_CHANNEL_TO_CHANGE: {
      return {
        ...state,
        channelToChange: action.channel
      };
    }
    case tvActions.SET_ACTIVE_USER_NUMBER: {
      return {
        ...state,
        activeUserNumber: action.userNumber
      };
    }
    case tvActions.TOGGLE_SHOW_CHANNEL_NUMBER_CHANGE: {
      return {
        ...state,
        showChannelNumberChange: action.showChannelNumberChange
      };
    }
    case tvActions.TOGGLE_SHOW_FILTER: {
      return {
        ...state,
        showFilter: action.showFilter,
        showSort: false
      };
    }
    case tvActions.TOGGLE_SHOW_SORT: {
      return {
        ...state,
        showFilter: false,
        showSort: action.showSort
      };
    } 
    case tvActions.RESET_CHANNEL_NUMBERS: {
      var n = state.allChannels.map((function(e) {
        return {...e, userNumber: e.number}
      })).sort((function(e, t) {
        return e.userNumber - t.userNumber
      }));

      return  {
        ...state,
        allChannels: n
      };
    } 
    case tvActions.SET_CHANNEL_ARCHIVED_TIME: {
      const newState =  {...state};
      newState.archivedTimes[action.channelId] = action.time
      return  newState
    }
    case tvActions.CHANGE_ACTIVE_COMPONENT: { 
      Stb.changeActiveComponent(action.name)
      console.log('changeActiveComponent: ' + action.name)

      return {
        ...state,
        activeComponent: action.name
      };
    }  
    case tvActions.CHANGE_ARCHIVE_URL: {
      const newState =  {...state};
      let liveUrl = newState.activeChannel.stream_live;  
      window.MyJSInterface.playArchiveUrl(newState.activeChannel.stream_rewind, action.timestamp ,liveUrl);
      return  newState
    }
    case tvActions.SET_DVR_TIMES: { 
      return {
        ...state,
         dvrStart: action.from,
         dvrEnd:  action.to
      }; 
    }  
    case tvActions.CHANGE_SCREEN: {
      return {
        ...state,
        screen: action.screen
      };
    } 
    case tvActions.CHANGE_ALERT: {
      return {
        ...state,
        alert: action.msg, 
        activeComponent: "AlertMessage"
      };
    } 
    case tvActions.CHANGE_LANG: {
      return {
        ...state,
        lang: action.lang
      };
    } 
    case tvActions.ADD_TO_ARCHIVE_MAP: { 
      const newMap = new Map(state.archiveMap);
      newMap.set(action.streamUrl, action.timestamp);
      return { ...state, archiveMap: newMap };
    } 
    case tvActions.DELETE_FROM_ARCHIVE_MAP: {
      const streamToDelete = action.streamUrl;
      const updatedMap = new Map(state.archiveMap);
      updatedMap.delete(streamToDelete);
      return { ...state, archiveMap: updatedMap /*, archiveCurrent: null*/ };
    }
    default:
      return state;
  } 
};

