import React from "react";
import { useEffect, useState } from "react";
import useTv from "../../hooks/useTv";
import { AppContext } from "../../providers/appProvider";
import Stb from "../../API/Stb";
import cl from "./DvrAlert.module.css";

const DvrAlert = () => {
  
  const componentName = "DvrAlert";
  const keyListenerName = "onKeyEmu" + componentName;
  const [yesNo, setYesNo] = useState(true);
  const [count, setCount] = useState(0);
  const [tvState, tv] = useTv(AppContext);

  const finishAlert = (yesNo) => {
    if (yesNo) {
      Stb.goToLive();
    }
    tv.setActiveComponent("");
  };

  useEffect(() => { 
    const onSecTicker = () => {
      setCount((count) => {
        const newCount = count + 1;
        if (newCount > 60) {
          finishAlert(true);
          return count;
        }
        return newCount;
      });
    };

    const intervalId = setInterval(() => {
      onSecTicker();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const onKeyEmu = (e) => {
      const androidKey = e.detail.androidKey;
      const reactKey = e.detail.key;
      switch (reactKey) {
        case window.keys.right:
          setYesNo(true);
          break;
        case window.keys.left:
          setYesNo(false);
          break;
        case window.keys.ok:
          finishAlert(yesNo);
          break;
        default:
          console.log(keyListenerName +" unknown keyClick: " + androidKey);
      }
      
    };
    window.addEventListener(keyListenerName, onKeyEmu);

    return () => {
      window.removeEventListener(keyListenerName, onKeyEmu);
    };
  }, [yesNo]);


  const sawe = `btnReloadYes ${yesNo ? 'active' : 'inactive'}`;


  return <div>

    <div className={cl.dvrAlert}>
        გსურთ ლაივში გასვლა? 
        <div className={cl.btns}>
            <div className={yesNo ? cl.btnReloadYes : cl.btnReloadYesSelected + ' ' + cl.btnReloadYes}>არა</div>
            <div className={yesNo ? cl.btnReloadYesSelected  + ' ' + cl.btnReloadYes : cl.btnReloadYes}>კი  <span>({61 - count})</span></div> 
        </div>
    </div>

  </div>;
};

export default DvrAlert;
