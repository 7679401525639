import React from 'react'
import { useContext, useState, useEffect } from 'react'
import { AppContext } from '../../providers/appProvider'
import useDidMountEffect from '../../hooks/useDidMountEffect'
import cl from './VolumeBar.module.css'
import mute from './mute.svg'
import volume from './volume.svg'
import Stb from "../../API/Stb";

const VolumeBar = () => {
  const [showBar, setShowBar] = useState(false) 
  const [startTs, setStartTs] = useState(Math.floor(Date.now() / 1000)) 
  const { playerState  } = useContext(AppContext)
  var intervalId 
 
  useEffect(() => { 
    const onEveryTicker = () => {
      const nowTs = Math.floor(Date.now() / 1000)
      const diffTs = nowTs - startTs
     
      if ( diffTs > 2 ) {
        setShowBar(false)
        clearInterval(intervalId)
      }
    };
 
    intervalId = setInterval(() => {
      onEveryTicker();
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, [startTs])

  useDidMountEffect(() => { 
    setShowBar(true) 
    Stb.setVolume(playerState.volume)
    setStartTs(Math.floor(Date.now() / 1000))
  }, [playerState.volume])

  return (
    <div> 
        {showBar && (
            <div className={cl.volumBox}>
              <img src={playerState.volume > 0 ? volume : mute}  className={cl.volumImg}   />

              <div className={cl.volumBar} > 
                  <div className={cl.progressBar}  style={{ width: playerState.volume + '%'}}></div>
              </div>
              <div className={cl.volumNumber} >{ playerState.volume}</div> 
            </div>
        )} 
    </div> 
  )
}

export default VolumeBar
