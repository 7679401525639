  
export const initialPlayerState = {
    isLive: true,
    volume: 0,
    previusVolume: 0,
    timestamp: 0,
    isRewinding: false, 
    direction: 2,
    rewindSpeed: 2,
    isPlaying: false
}

const playerActions = {
    SET_PLAYER_IS_LIVE: "SET_PLAYER_IS_LIVE",
    SET_PLAYER_TIMESTAMP: "SET_PLAYER_TIMESTAMP",
    SET_PLAYER_IS_REWINDING: "SET_PLAYER_IS_REWINDING",
    SET_PLAYER_DIRECTION: "SET_PLAYER_DIRECTION",
    SET_PLAYER_REWIND_SPEED: "SET_PLAYER_REWIND_SPEED", 
    SET_PLAYER_IS_PLAYING: "SET_PLAYER_IS_PLAYING",  
    SET_PLAYER_VOLUME: "SET_PLAYER_VOLUME",
    SET_PLAYER_VOLUME_MUTE: "SET_PLAYER_VOLUME_MUTE",  
};

export const playerReducer = (state, action) => {
  switch (action.type) {
    case playerActions.SET_PLAYER_IS_LIVE: {
      return {
        ...state,
        status: action.status
      };
    }
    case playerActions.SET_PLAYER_TIMESTAMP: {
      let newState = {
        ...state,
        timestamp: action.timestamp
      };

      return newState
    }
    case playerActions.SET_PLAYER_IS_REWINDING: {
      console.log("SET_PLAYER_IS_REWINDING : "+ action.status)

      let newState = {
        ...state,
        isRewinding: action.status
      };

      return newState
    }

    case playerActions.SET_PLAYER_DIRECTION: {
      console.log("SET_PLAYER_DIRECTION : "+ action.direction)

      let newState = {
        ...state,
        direction: action.direction
      };

      return newState
    }
    case playerActions.SET_PLAYER_REWIND_SPEED: {
      console.log("SET_PLAYER_REWIND_SPEED : "+ action.speed)

      let newState = {
        ...state,
        rewindSpeed: action.speed
      };

      return newState
    }
    case playerActions.SET_PLAYER_IS_PLAYING: {
      let newState = {
        ...state,
        isPlaying: action.status
      };

      return newState
    }
    case playerActions.SET_PLAYER_VOLUME: {
      var newVolume = state.volume + action.volume
      console.log("SET_PLAYER_VOLUME : "+ newVolume)

      const min = 0;  
      const max = 100;   

      if (newVolume < min) { 
        newVolume = min; 
      } else if (newVolume > max) { 
        newVolume = max; 
      }

     
      return {
        ...state,
        volume: newVolume
      };
    }
    case playerActions.SET_PLAYER_VOLUME_MUTE: {
      var newVolume = state.previusVolume

      if (state.volume > 0) {
         newVolume = 0
      }  

      return {
        ...state,
        volume: newVolume,
        previusVolume: state.volume 
      }
    }
    default:
      return state;
  }
}; 