import React from "react";
import { useEffect, useState } from "react";
import useTv from "../../hooks/useTv";
import { AppContext } from "../../providers/appProvider";
import Stb from "../../API/Stb";
import cl from "./ArchiveAlert.module.css";

const ArchiveAlert = () => {
  
  const componentName = "ArchiveAlert";
  const keyListenerName = "onKeyEmu" + componentName;
  const [isLive, setIsLive] = useState(true); 
  const [tvState, tv] = useTv(AppContext);

  const finishAlert = (isLive) => {
    const archiveCurrent = tvState.archiveCurrent
    console.log('ArchiveAlert fin: ' + JSON.stringify(archiveCurrent)) 
    tv.deleteArchiveChannel(archiveCurrent.streamUrl)
    
    if (isLive) {
      tv.setActiveChannel(archiveCurrent.idx, 0 )
    } else {
      tv.setActiveChannel(archiveCurrent.idx, archiveCurrent.timestamp)
    }
  

     tv.setActiveComponent("")
  };
  
  useEffect(() => {
    console.log('ArchiveAlert useEffect: isLive '+ isLive)

    const onKeyEmu = (e) => {   
      const androidKey = e.detail.androidKey;
      const reactKey = e.detail.key;
      console.log('ArchiveAlert onKeyEmu: ' + reactKey)

      switch (reactKey) {
        case window.keys.right:
          setIsLive(true);
          break;
        case window.keys.left:
          setIsLive(false);
          break;
        case window.keys.ok:
          console.log('ArchiveAlert window.keys.ok: isLive ' + isLive)
          finishAlert(isLive);
          break;
        default:
          console.log(keyListenerName +" unknown keyClick: " + androidKey);
      } 
    };
    window.addEventListener(keyListenerName, onKeyEmu);

    return () => {
      window.removeEventListener(keyListenerName, onKeyEmu);
    };
  }, [isLive]);
 
  return <div>

    <div className={cl.dvrAlert}>
       არხი უკრავდა არქივს, გსურთ ლაივში გადასვლა? 
        <div className={cl.btns}>
            <div className={isLive ? cl.btnReloadYes : cl.btnReloadYesSelected + ' ' + cl.btnReloadYes}>არქივში დაბრუნება</div>
            <div className={isLive ? cl.btnReloadYesSelected  + ' ' + cl.btnReloadYes : cl.btnReloadYes}>ლაივში გადასვლა </div> 
        </div>
    </div>

  </div>;
};

export default ArchiveAlert;
