import { AppContext } from "../../providers/appProvider";
import React, { useState } from "react";
import ReactLload from "./load.svg";
import { useEffect } from "react";
import useTv from "../../hooks/useTv";
import useCmd from "../../hooks/useCmd"; 
import useLang from "../../hooks/useLang"; 
import { Screen } from "../../API/Screen";

import Stb from "../../API/Stb";
import cl from "./Loader.module.css";

const Loader = () => {
  const [tvState, tv] = useTv(AppContext);
  const [cmd] = useCmd(AppContext);
  const [lang] = useLang(AppContext);

  const [loadText, setLoadText] = useState("LOADING");

  const [loadPercent, setloadPercent] = useState(0);

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  
  useEffect(() => {
      console.log("render useEffect")
      startLoading()
  }, []);

  const getCurrentEpg = (epgs) => {
    var epg = Stb.getCurrentEpg(epgs)  
    if (epg === null) {
      return "პროგრამა მიუწვდომელია"
    }   
    return epg.name
}

  const setChannels = (channels, tokenVal) => {
    const token = "?token=" + tokenVal
    const modifiedChannels = channels.map(channel => { 
      const modifiedChannel = {
        ...channel, 
        stream_live: channel.stream_live + window.streamIndex + token, 
        stream_rewind:  channel.stream_live + window.rewindUrl + token,
        preview_url: channel.stream_live + window.previewUrl  + token,
        statistics_url: channel.stream_live + window.statisticsUrl  + token, 
        timestamp: 0
      };

      return modifiedChannel;
    });

    const epgs = channels.map(channel => { 
      const epg = { 
        current: getCurrentEpg(channel.epg), 
        epg: channel.epg  
      };

      return epg;
    });
 
    console.log(JSON.stringify(epgs));

    tv.setAllChannels(modifiedChannels);
    tv.setAllEpgs(epgs);
  };

  const preloadImages = (channels) => {
    window.channelLogos = [];

    var i = 0;
    channels.forEach(function (channel) {
      window.channelLogos[i] = new Image();
      window.channelLogos[i].src = channel.logo;
      i++;
    });
  };

  const setLoading = async (text, percent)  => {   
    setLoadText(text);
    setloadPercent(percent);
  }

  const startLoading = async () => { 
    console.log("loading channels");
    Stb.initStb();
    Stb.setVolume(0);

    try {
      setLoading(lang.LOADING_DATA, 10);
      var registerInfo = await Stb.register();  
      console.log(JSON.stringify(registerInfo));

      console.log("inLoadingStart")

      if (registerInfo.status === 1) {
        console.log("saveToken:" + registerInfo.token)
        Stb.saveToken(registerInfo.token);
      } else if (registerInfo.status === 0) {
        console.log("mac not find") 
        setLoading(lang.MAC_NOT_FIND, 20);
        cmd.restartApp()
        return;
      } 
  
      var clientInfo = await Stb.clientInfo();
      console.log(JSON.stringify(clientInfo));
      Stb.setClientInfo(clientInfo);

      await delay(1000)
      setLoading(lang.LOADING_CONFIGURATION, 20); 

      if (registerInfo.disable ) { 
        tv.setScreen(Screen.NOPAYMENT);
        return;
      }

      await delay(1000)
      var channelsResp = await Stb.getAllChannel() 
      setLoading(lang.LOADING_CHANNELS, 40);
      setChannels(channelsResp.data, window.token);
      await delay(1000)
      setLoading(lang.LOADING_PICS, 65); 
      preloadImages(channelsResp.data);
      await delay(1000) 
      setLoading(lang.LOADING_DATA, 100); 
      await delay(1000)
      //tv.setLanguage(Stb.getLanguage())
      tv.setScreen(Screen.TV);
      tv.setActiveChannel(0);
    } catch (e) {
      console.log(lang.LOADING_CONFIGURATION_ERR + e.message) 
      setLoading(lang.LOADING_CONFIGURATION_ERR + e.message, 0);
      cmd.restartApp()
      return;
    }
  }

  return (
    <div className={cl.loadPage}>
        

      <div className={cl.loadBoxBar}>
        <div
          className={cl.loadProgressBar}
          style={{ width: loadPercent + "%" }}
        ></div>
      </div>
      <h1 className={cl.h1load}>სტატუსი : {loadText}</h1>
    </div>
  );
};

export default Loader;
