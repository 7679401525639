import  { useContext,  } from 'react';
import Stb from "../API/Stb";

const useTv = (context) => {
    const {tvState, tvDispatch} = useContext(context);

    function setActiveComponent(componentName) {
        tvDispatch({ type: "CHANGE_ACTIVE_COMPONENT",  name: componentName });
    }

    function showFooterTv(status) {
        tvDispatch({ type: "CHANGE_SHOW_FOOTER_TV", status: status });
    }

    function upActiveChannel() {
        tvDispatch({ type: "UP_ACTIVE_CHANNEL" });
    }

    function downActiveChannel() {
         tvDispatch({ type: "DOWN_ACTIVE_CHANNEL" });
    }

    async function  setDvrTimes(url)
    {
        const dvrTimes = await Stb.fetchDvrTimes(url);
        if (dvrTimes != null) {
          console.log("setDvrTimes:" + url + " " + dvrTimes.from)
          Stb.setDvrTimes(dvrTimes.from, dvrTimes.to);
          tvDispatch({
            type: "SET_DVR_TIMES",
            from: dvrTimes.from,
            to: dvrTimes.to,
          });
        } else {
            console.log("setDvrTimes: null response");
        }
    }

    function showTvMenu(status, reverse) {
        tvDispatch({ type: 'CHANGE_SHOW_TV_MENU', status: status, reverse: reverse })
    }

    function setArchiveUrl(ts) {
        tvDispatch({ type: 'CHANGE_ARCHIVE_URL', timestamp: ts })  
    }

    function setAllChannels(channels) {
        tvDispatch({type: 'SET_ALL_CHANNELS', payload: channels}) 
    }

    function setAllEpgs(epgs) {
        tvDispatch({type: 'SET_ALL_EPGS', payload: epgs}) 
    } 

    function setRendered(channels) {
        tvDispatch({type: 'SET_ALL_RENDERED', payload: channels}) 
    }

    function setActiveChannel(index, timestamp) {
        tvDispatch({ type: 'CHANGE_ACTIVE_CHANNEL', index: index, timestamp: timestamp}) 
    }

    function setActiveChannelMenu(index, timestamp) {
        tvDispatch({ type: 'CHANGE_ACTIVE_CHANNEL_MENU', index: index, timestamp: timestamp}) 
    }


    function setScreen(screen) {
        console.log("setScreen:"+ screen)
        tvDispatch({ type: 'CHANGE_SCREEN', screen: screen}) 
    }

    function showAlert(text) {
        tvDispatch({ type: 'CHANGE_ALERT', alert: text}) 
    }

    function setLanguage(lang) {
        Stb.saveLang(lang)
        tvDispatch({ type: 'CHANGE_LANG', lang: lang}) 
    }

    function addArchiveChannel(streamUrl, timestamp ) {
        tvDispatch({type: 'ADD_TO_ARCHIVE_MAP',  streamUrl: streamUrl, timestamp: timestamp }) 
    }

    function deleteArchiveChannel(streamUrl) {
        tvDispatch({type: 'DELETE_FROM_ARCHIVE_MAP',  streamUrl: streamUrl}) 
    }
 
    const tv = { 
        setActiveComponent,
        showFooterTv,
        upActiveChannel,
        downActiveChannel,
        setDvrTimes,
        showTvMenu, 
        setArchiveUrl,
        setAllChannels,
        setAllEpgs,
        setActiveChannel,
        setActiveChannelMenu,
        setScreen,
        setRendered,
        showAlert,
        setLanguage,
        addArchiveChannel,
        deleteArchiveChannel 
      };

    return [
        tvState,
        tv
    ];
}

export default useTv;
