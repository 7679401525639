import React from "react";
import { useContext } from "react";
import { AppContext } from "../../providers/appProvider";
import cl from "./FooterTv.module.css";
import { useEffect, useState } from "react";
import Stb from "../../API/Stb";
import useTv from "../../hooks/useTv";
import usePlayer from "../../hooks/usePlayer";

const FooterTv = () => {
  const [tvState, tv] = useTv(AppContext);
  const [playerState, player] = usePlayer(AppContext);

  var playerInfo = Stb.getPlayerInfo();
  const [playerStatus, setPlayerStatus] = useState({
    isPlaying: playerInfo.isPlaying,
    isLive: playerInfo.isLive,
    isRewinding: playerInfo.isRewinding,
    direction: playerInfo.direction,
    rewindSpeed: playerInfo.rewindSpeed,
    timestamp: playerInfo.timestamp,
    state: playerInfo.state,
  });

  const getCurrentEpg = (epgs) => { 
    var epg = Stb.getCurrentEpg(epgs)  
 
    if (epg === null) {
      return "პროგრამა მიუწვდომელია"
    }   
    return epg.name
  }

  const isHideFooter = (footerStartTs, state) => {
    const nowTs = Math.floor(Date.now() / 1000);
    var isOutdateTime = false;
    var sec = 4;

    if (nowTs - footerStartTs > sec) {
      isOutdateTime = true;
    }

    if ((state === "onArchive" || state === "onLive") && isOutdateTime) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const onEveryTicker = () => {
      var playerInfo = Stb.getPlayerInfo();
      console.log("playerInfo onEveryTicker: milliseconds");

      if (playerInfo.archiveNotiferEnable) {
        tv.setActiveComponent("DvrAlert");
      }

      if (isHideFooter(tvState.footerStartTs, playerInfo.state)) {
        tv.showFooterTv(false);
        return;
      }

      setPlayerStatus({
        ...playerStatus,
        isPlaying: playerInfo.isPlaying,
        isLive: playerInfo.isLive,
        isRewinding: playerInfo.isRewinding,
        rewindSpeed: playerInfo.rewindSpeed,
        direction: playerInfo.direction,
        timestamp: playerInfo.timestamp,
        state: playerInfo.state,
      });
    };

    onEveryTicker();

    const intervalId = setInterval(() => {
      onEveryTicker();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [tvState.footerStartTs]);

  const geoTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const offsetInMinutes = date.getTimezoneOffset();
    const georgianTimezoneOffset = 240 + offsetInMinutes;

    date.setUTCMinutes(date.getUTCMinutes() + georgianTimezoneOffset);

    const hour = date.getHours().toString().padStart(2, "0");
    const minute = date.getMinutes().toString().padStart(2, "0");
    const second = date.getSeconds().toString().padStart(2, "0");
    const formattedTime = `${hour}:${minute}:${second}`;
    return formattedTime;
  };

  const geoDay = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const offsetInMinutes = date.getTimezoneOffset();
    const georgianTimezoneOffset = 240 + offsetInMinutes;

    date.setUTCMinutes(date.getUTCMinutes() + georgianTimezoneOffset);
    const monthOfYaer = [
      "იანვარი",
      "თებერვალი",
      "მარტი",
      "აპრილი",
      "მაისი",
      "ივნისი",
      "ივლისი",
      "აგვისტო",
      "სექტემბერი",
      "ოქტომბერი",
      "ნოემბერი",
      "დეკემბერი",
    ];
    const daysOfWeek = [
      "კვირა",
      "ორშაბათი",
      "სამშაბათი",
      "ოთხშაბათი",
      "ხუთშაბათი",
      "პარასკევი",
      "შაბათი",
    ];
    const weekday = daysOfWeek[date.getDay()];
    const month = date.getDate() + " " + monthOfYaer[date.getMonth()];
    return weekday + ", " + month + ", ";
  };

  return (
    <div className={cl.footerBox}>
      <div className={cl.leftFooter}>
        <img className={cl.tvLogo} src={tvState.activeChannel.logo} />
        <div className={cl.descTV}>
          <h1 className={cl.h1}> {tvState.activeChannel.name}</h1>
          <h2 className={cl.h2}> {getCurrentEpg(tvState.activeChannel.epg)}</h2>

          <div className={cl.liveАrchive}>
            <span className={cl.live}>
              <span
                className={
                  playerStatus.isLive ? cl.liveАrchiveSvg : cl.reliveАrchiveSvg
                }
              ></span>
              {playerStatus.isLive ? "Live" : "არქივი"}
            </span>
          </div>
        </div>
      </div>

      <div className={cl.rightFooter}>
        <div className={cl.tvnumberReward}>
          {playerInfo.state === "onPause" && (
            <div className={cl.reward}>
              <span className={cl.speedward}>პაუზა</span>
              <span className={cl.pause}></span>
            </div>
          )}

          {playerInfo.state === "onRewind" && (
            <div className={cl.reward}>
              <span className={cl.speedward}>x{playerState.rewindSpeed}</span>
              <span
                className={playerState.direction ? cl.forward : cl.backward}
              ></span>
            </div>
          )}

          <div className={cl.tvnumber}>
            {tvState.activeChannel.channel_number}
          </div>
        </div>

        <div className={cl.timePlay}>
          <span className={cl.dayTV}>{geoDay(playerStatus.timestamp)}</span>
          <span className={cl.timeTV}>{geoTime(playerStatus.timestamp)}</span>
        </div>
      </div>
    </div>
  );
};

export default FooterTv;
