import React from "react";
import { AppContext } from "../../providers/appProvider";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import usePlayer from "../../hooks/usePlayer";
import useTv from "../../hooks/useTv";
import Stb from "../../API/Stb";

const TimeShifter = ({ direction }) => {
  const [tvState, tv] = useTv(AppContext);
  const [playerState, player] = usePlayer(AppContext);

  const startRewind = (direction) => {
    tv.showTvMenu(false, false);
    tv.showFooterTv(true);
    var playerInfo = Stb.getPlayerInfo();
    var directionBool = true;

    var isRewinding = playerInfo.isRewinding;
    var rewindSpeed = 2;

    if (direction.status == 1) {
      directionBool = false;
    }

    if (isRewinding && (directionBool == playerInfo.direction)) {
      rewindSpeed = playerInfo.rewindSpeed * 2;
      if (rewindSpeed > 64) {
        rewindSpeed = 2;
      }
    } else {
      rewindSpeed = playerInfo.rewindSpeed;
    }

   
    if (directionBool && playerInfo.state === "onLive") {
      return
    } 

    player.setDirection(directionBool)
    player.setRewindSpeed(rewindSpeed)
    Stb.startRewind(directionBool, rewindSpeed, 0);
  };

  const handleOkPress = () => {
    var playerInfo = Stb.getPlayerInfo();

    if (playerInfo.state === "onRewind") {
      window.MyJSInterface.stopRewind();
      tv.showFooterTv(false);
      tv.setArchiveUrl(playerInfo.timestamp);
    } else {
      tv.showFooterTv(false);
      tv.showTvMenu(false, true);
    }
  };

  useDidMountEffect(() => {
    if (direction.status > 1) {
      handleOkPress();
    } else {
      startRewind(direction);
    }
  }, [direction]);

  return <div> </div>;
};

export default TimeShifter;
