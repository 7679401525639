import  { useContext,useState } from 'react';
import Stb from "../API/Stb";
import { Language } from "../API/Language";

const useLang = (context) => {  
    var word = {}
    word.LOADING_CONFIGURATION = "მოწყობილობის კონფიგურაცია"
    word.LOADING_CONFIGURATION_ERR = "შეცდომა კონფიგურაციისა: "
    word.MAC_NOT_FIND = "ბილინგში მოწყობილობა ვერ მოიძებნა"
    word.LOADING_USER = "შეცდომა კონფიგურაციისა: "
    word.LOADING_USER_ERR = "მომხმარებლის განახლება"
    word.LOADING_CHANNELS = "არხები გადმოწერა"
    word.LOADING_PICS = "სურათების გადმოწერა" 
    word.LOADING_CHANNELS_ERR = "მიმდინარეობას მომხმარებლის განახლებისას"
    word.LOADING_DATA = "მონაცემების განახლება"

    word.LOADING_DATA_ERR = "მიმდინარეობას მომხმარებლის განახლებისას"
 
    return [word]
}

export default useLang;
