import React from "react";
import {useContext, useState, useRef} from "react";
import {AppContext} from "../../providers/appProvider";
import useDidMountEffect from '../../hooks/useDidMountEffect';
import Utils from "../../API/Utils";
import useTv from "../../hooks/useTv";
import cl from "./ChannelChanger.module.css";

const ChannelChanger = ({ keyDownNum }) => {
  const [readyNum, setReadyNum] = useState("");
  const numRef = useRef(readyNum);
  numRef.current = readyNum;
  const [tvState, tv] = useTv(AppContext);
  var timeout;
 
  const changerFunc = () => { 
    const number = parseInt(numRef.current, 10);
    if (isNaN(number)) {
      console.log("channel change not int:" + numRef.current);
      setReadyNum("");
    } else {
      console.log("channel change:" + numRef.current);
      tv.setActiveChannel(number - 1, 0)
      setReadyNum("");
    } 
  }

  useDidMountEffect(() => {
    var num = readyNum + keyDownNum.key;

    if ( num.length > 3) {
       num = keyDownNum.key
    }
    
    setReadyNum(num); 

    timeout = setTimeout(() => {
      changerFunc();
    }, 1500);

    return () => {
      clearInterval(timeout);
    }; 
  }, [keyDownNum]);

   return (
      <div className={cl.fixNumber}>  
       <h1 style={{color: "white"}}>{readyNum}</h1>
      </div>
    )
}

export default ChannelChanger

 