import React from "react";
import useTv from "../../hooks/useTv";
import Stb from "../../API/Stb";
import { AppContext } from "../../providers/appProvider";

import { Component } from "../../API/Component";
import { useEffect, useState } from "react";

const LanguageSetting = () => {
  const componentName = "AudioLang";
  const keyListenerName = "onKeyEmu" + componentName;
  const [tvState, tv] = useTv(AppContext) 
  const [items, setItems] = useState([]);
  const [activeIdx, setActiveIdx] = useState(0);


  const setActiveLang = (idx) => {
    console.log("setActiveLang:" + idx); 
    Stb.setAudioLang(items[idx].language)
    setActiveIdx(idx) 
  };

  useEffect(() => {
    var langs = Stb.getAudioList()
    var selectedIndex = langs.findIndex(item => item.selected === true);  
    setItems(langs);
    setActiveIdx(selectedIndex);
  }, []);


  useEffect(() => { 
    const onKeyEmu = (e) => {  
      const androidKey = e.detail.androidKey;
      const reactKey = e.detail.key;
       switch (reactKey) {
        case window.keys.right: 
          var idx = activeIdx + 1; 
          console.log("activeIdx:" + activeIdx + " idx:" + idx + " items.length:" + items.length )
          if ( idx < items.length ) {
            setActiveLang(idx) 
          } 
          break;
        case window.keys.left:
          var idx = activeIdx - 1; 
          console.log("activeIdx:" + activeIdx + " idx:" + idx + " items.length:" + items.length )  
           if ( idx >= 0 ) { 
            setActiveLang(idx); 
          } 
          break;
        case window.keys.ok:
          tv.setActiveComponent(Component.EMPTY)
          break;
        default:
          console.log(keyListenerName + " unknown keyClick: " + androidKey);
      }
    };
    window.addEventListener(keyListenerName, onKeyEmu);

    return () => {
      window.removeEventListener(keyListenerName, onKeyEmu);
    };
  }, [items,activeIdx]);

  return <div>  {items.map((item, idx) => (
    <h1 style={{ color: idx === activeIdx ? 'red' : 'green' }}>
      {item.language}
    </h1>
  ))} </div>;
};

export default LanguageSetting;
