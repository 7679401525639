import React from "react";
import cl from './ClientInfo.module.css'

const ClientInfo = () => {
  
    return ( 
        <div className={cl.clientBox}> 
            <div className={cl.clientBoxInner}>
                    <div class={cl.clientName}>დეტალური ინფორმაცია</div> 
                    <div class={cl.clientInfo}>აბონენტი: {window.firstname} {window.lastname}</div>
                    <div class={cl.clientInfo}>ინტერნტი: {window.packageNET}</div>
                    <div class={cl.clientInfo}>ტელევიზია: {window.packageTV}</div>
                    <div class={cl.clientInfo}>ბალანსი: {window.balance} ლარი</div> 
            </div> 
        </div> 
    )
}

export default ClientInfo