import  { useContext, useState,  } from 'react';
import Stb from "../API/Stb";
import useTv from "../hooks/useTv";
import { Component } from '../API/Component'; 

 
const useCmd = (context) => {
    const [tvState, tv] = useTv(context);
    const [msg, setMsg] = useState("")

    function execute(cmd, text) { 
        switch (cmd) {
            case "restartdevice":
                restartDevice()
              break;
            case "restarapp":
                restartApp()
              break; 
            case "cmdandroid":
              cmdAndroid(text)
              break;
            case "message":
              showMessage(text)
              break;
            default: 
              break;
          }
    }

    function restartDevice() {
      Stb.restartDevice()
    }

    function restartApp() {
      Stb.restartApp()
    }

    function showMessage(txt) { 
        setMsg(txt)
        tv.setActiveComponent(Component.ALERT_MESSAGE)
    }

    function cmdAndroid(command) { 
      const resp = Stb.cmdAndroid(command)
      if (resp.length > 0) {
        Stb.PostResponse(resp)
      }
    }

    const cmd = { 
        execute,
        restartDevice,
        restartApp,
        showMessage,
        cmdAndroid,
        msg,
    };

    return [cmd]
}

export default useCmd;
