import { AppContext } from "../../providers/appProvider";
import ChannelListItem from "../ChannelListItem/ChannelListItem";
import cl from "./ChannelList.module.css";
import useTv from "../../hooks/useTv";
import { useEffect } from "react";
import Stb from "../../API/Stb";


const ChannelList = () => {
  const [tvState, tv] = useTv(AppContext);

  var channelsToDisplay = tvState.allChannels;
  var showPerPage = 10; 

  const getCurrentEpg = (epgs) => {
     
    if (epg === null) {
      return "პროგრამა მიუწვდომელია"
    }

    var epg = Stb.getCurrentEpg(epgs)  
    if (epg === null) {
      return "პროგრამა მიუწვდომელია"
    }   

    return epg.name
}

  const setCurrentEpgs = (epgs) => { 
    const epgsTmp = epgs.map(epgEach => {  
      const epg = { 
        ...epgEach,
        current: getCurrentEpg(epgEach.epg)  
      };

      return epg;
    });
  
    tv.setAllEpgs(epgsTmp);
  };

  useEffect(() => {
    console.log("useEffect length: " + tvState.allEpgs.length); 
    console.log("useEffect" + JSON.stringify(tvState.allEpgs));

    setCurrentEpgs(tvState.allEpgs)
  }, []);
 
  const preRenderChannels = (arr, size) => {
    const groups = [];
    for (let i = 0; i < arr.length; i += size) {
      var group = { startIdx: 0, endIdx: 0, channels: [], html: null };
      group.startIdx = i;
      group.endIdx = i + size;
      group.channels = arr.slice(group.startIdx, group.endIdx);
      group.html = (
        <div className={cl.channelsList}>
          <table className={cl.channelTable}>
            <tr className={cl.headerTable}>
              <th className={cl.number}>№</th>
              <th className={cl.logoChanel}></th>
              <th>არხი</th>
              <th>პროგრამა</th> 
            </tr>
            {group.channels.map((channel, idx) => (
              <ChannelListItem
                channel={channel}
                key={group.startIdx + idx}
                idx={group.startIdx + idx}
                channelState={tvState}
              />
            ))} 
          </table>
        </div>
      ); 
      groups.push(group);
    }
    return groups;
  };

  var groupChannels = preRenderChannels(channelsToDisplay, showPerPage);

  return (
    <div style={{
      position: 'relative', 
    }} >
      {groupChannels.map((group, idx) => (
        <div
        style={{
          position: 'absolute',
          left: '0',
          top: '0',
          zIndex: idx+1,
          width: '100%',  
        }}
          className={
            tvState.activeChannelIndex >= group.startIdx &&
            tvState.activeChannelIndex < group.endIdx
              ? cl.show_list
              : cl.hide_list
          }
        >
          {" "}
          {group.html}

          <div className={cl.channelsFound}>
            <div className={cl.channelsFoundBox}>სულ {channelsToDisplay.length} არხი</div>
          </div>
        </div>
      ))}

    </div>
  );
};

export default ChannelList;
