import { AppProvider} from './providers/appProvider';
import './App.css';
import Main from './components/Main';


function App() {
  return (
    <AppProvider>
      <Main /> 
    </AppProvider>
  );
}

export default App;
